.header {
  color: #fff;
  align-items: center;
  min-height: 50px;
  padding: .5rem 1rem;
  display: flex;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.header-title:after {
  content: " ▸ ";
  padding: 0 .5rem;
}

.header-subtitle {
  font-size: 1.2rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

.ais-Highlight-highlighted {
  color: inherit;
  font-size: inherit;
}

#pagination {
  text-align: center;
  margin: 2rem auto;
}

#rheostat-value {
  display: none;
}

.ais-Panel {
  padding: 1em;
}

.ais-RefinementList-label {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.ais-RefinementList-labelText {
  flex-grow: 1;
}

.ais-RefinementList-count {
  flex-basis: content;
}

/*# sourceMappingURL=index.09ab46e5.css.map */
